import React, { useEffect, useMemo, useRef } from 'react';
import { FixedSizeList, areEqual } from 'react-window';
import { Field, useForm, useFormState } from '@/Framework/UI/Organisms/FinalForm';
import isEmptyString from '@/Framework/dataHelpers/string/isEmptyString';
import VirtualList, { IVirtualListChildrenProps } from '@dealroadshow/uikit/core/components/Table/VirtualList';
import AppLabel from '@/dmPortal/ui/common/AppLabel';
import FilterItem from '@/Framework/UI/Organisms/Filters/components/FilterItem';
import { IFormattedDeal } from '@/dmPortal/domain/vo/crossDealAnalytics/Filters';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import Checkbox from '@dealroadshow/uikit/core/components/Checkbox';
import TruncateTextWithTooltip from '@dealroadshow/uikit/core/components/Tooltip/TruncateTextWithTooltip';
import { filterDealsBy } from './constants';
import filtersStyles from '@/Framework/UI/Organisms/Filters/filters.scss';
import styles from './deals.scss';

const DealsListItem = React.memo(({
  data,
  style,
}: IVirtualListChildrenProps<IFormattedDeal>) => {
  const {
    id,
    name,
    tenant,
    canChangeResponse,
  } = data;
  const form = useForm();
  const state = useFormState();
  return (
    <div className={ styles.row } style={ style }>
      <div className={ styles.appLabel }>{ tenant && <AppLabel tenant={ tenant } /> }</div>
      <FilterItem>
        <Field
          type="checkbox"
          name={ filterDealsBy[tenant] }
          component={ ({
            meta,
            input,
          }) => (
            // @ts-ignore
            <Checkbox
              { ...input }
              meta={ meta }
              dataTest={ `deal ${ name } checkbox` }
              label={ <TruncateTextWithTooltip value={ name } /> }
              value={ id }
              checked={ state.values[filterDealsBy[tenant]]?.includes(id) }
              onChange={ (event) => {
                const { checked } = event.target;
                if (checked) {
                  form.change(filterDealsBy[tenant], [...state.values[filterDealsBy[tenant]], id]);
                } else {
                  form.change(
                    filterDealsBy[tenant],
                    state.values[filterDealsBy[tenant]].filter((dealId) => dealId !== id),
                  );
                }
              } }
              disabled={ !canChangeResponse }
            />
          ) }
        />
      </FilterItem>
    </div>
  );
}, areEqual);

interface IProps {
  search: string,
  selectedValues: string[],
  selectedTenants: string[],
}

const DealsList = ({
  search,
  selectedValues,
  selectedTenants,
}: IProps) => {
  const {
    dealsFilters: {
      deals,
      tenants,
    },
    isSidebarOpen,
  } = useGlobalAnalyticsFiltersContext();

  const listRef = useRef<FixedSizeList>();

  useEffect(() => {
    /**
     * move list to start when open sidebar
     * 'cause selected deals moved to top
     */
    if (isSidebarOpen) {
      listRef.current?.scrollToItem(0);
    }
  }, [isSidebarOpen]);

  /**
   * Filter items with selected tenants
   */
  const filteredItems = useMemo(() => {
    if (selectedTenants.length === 0 || selectedTenants.length === tenants.length) {
      return deals;
    }

    return deals.filter((item) => selectedTenants.includes(item.tenant));
  }, [deals, selectedTenants, tenants]);

  /**
   * Filter items with search keyword
   */
  const searchedItems = useMemo(() => {
    if (isEmptyString(search)) {
      return filteredItems;
    }

    const searchInLowerCase = search.toLowerCase();

    return filteredItems.filter((item) => item.nameInLowerCase.includes(searchInLowerCase));
  }, [filteredItems, search]);

  /**
   * Sort items: move selected items to the top
   */
  const sortedItems = useMemo(() => {
    if (selectedValues.length === 0) {
      return searchedItems;
    }

    const settedValues = new Set(selectedValues);
    const selectedChildren: IFormattedDeal[] = [];
    const noSelectedChildren: IFormattedDeal[] = [];

    searchedItems.forEach((item) => {
      if (settedValues.has(item.id)) {
        selectedChildren.push(item);
      } else {
        noSelectedChildren.push(item);
      }
    });

    return [...selectedChildren, ...noSelectedChildren];
  }, [searchedItems, selectedValues]);

  if (sortedItems.length === 0) {
    return (
      <span className={ filtersStyles.filterSearchZerocase }>
        No results found { !isEmptyString(search) && ` for ${ search }` }
      </span>
    );
  }

  return (
    <VirtualList<IFormattedDeal>
      ref={ listRef }
      data={ sortedItems }
      itemKey={ (item) => `${ item.tenant }: ${ item.id }` }
      height={ 300 }
      itemSize={ 23 }
    >
      { DealsListItem }
    </VirtualList>
  );
};

export default React.memo(DealsList);
