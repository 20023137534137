import { useState, useEffect } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AllocateRole } from '@/allocate/application/Allocations/PermissionsContext/constants';
import AllocationsAnalyticsRepository from '@/allocate/infrastructure/repository/AllocationsAnalyticsRepository';
import BlacklistRepository from '@/dealroadshow/infrastructure/repository/BlacklistRepository';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';

const usePermissions = () => {
  const { container } = useDIContext();
  const allocationsAnalyticsRepository = container.get<AllocationsAnalyticsRepository>(AllocationsAnalyticsRepository);
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);
  const blacklistRepository = container.get<BlacklistRepository>(BlacklistRepository);

  const [isSupportAdmin, setIsSupportAdmin] = useState(false);
  const [isAdminAnalyticsOnly, setIsAdminAnalyticsOnly] = useState(false);
  const [isAdminBlacklisted, setIsAdminBlacklisted] = useState(false);
  const [isAdminBlacklistedFetched, setIsAdminBlacklistedFetched] = useState(false);

  /**
   * Get allocate user role and support admin status
   */
  const getCurrentUserRole = async (dealAllocationId?: string): Promise<{
    isSupportAdmin: boolean,
    isAdminAnalyticsOnly: boolean,
  }> => {
    try {
      const {
        isAdmin,
        currentAllocateRole,
      } = await allocationsAnalyticsRepository.getCurrentUserRole({ dealAllocationId });

      const isAnalyticsOnly = currentAllocateRole === AllocateRole.ManagerAnalyticsOnly;
      setIsSupportAdmin(isAdmin);
      setIsAdminAnalyticsOnly(isAnalyticsOnly);

      return {
        isSupportAdmin: isAdmin,
        isAdminAnalyticsOnly: isAnalyticsOnly,
      };
    } catch (error) {
      AlertManager.error(getErrorMessage(error));

      return {
        isSupportAdmin: false,
        isAdminAnalyticsOnly: true,
      };
    }
  };

  /**
   * Check if admin is blacklisted
   */
  const checkIsAdminBlacklisted = async (): Promise<void> => {
    try {
      const response = await blacklistRepository.getApplicationIsBlacklisted();
      setIsAdminBlacklisted(response);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsAdminBlacklistedFetched(true);
    }
  };

  /**
   * Check if admin have analytics only roadshow permissions by roadshowId
   */
  const checkIsAdminAnalyticsOnlyByRoadshowId = async (roadshowId: string): Promise<boolean> => {
    try {
      const response = await roadshowRepository.isAdminAnalyticsOnlyByRoadshowId({ roadshowId });
      setIsAdminAnalyticsOnly(response);
      return response;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      return true;
    }
  };

  useEffect(() => {
    checkIsAdminBlacklisted();
    getCurrentUserRole();
  }, []);

  return {
    isSupportAdmin,
    getCurrentUserRole,
    isAdminAnalyticsOnly,
    setIsAdminAnalyticsOnly,
    isAdminBlacklisted,
    isAdminBlacklistedFetched,
    checkIsAdminBlacklisted,
    checkIsAdminAnalyticsOnlyByRoadshowId,
  };
};

export default usePermissions;
