import React, { createContext, useContext, useState } from 'react';
import MeetingsRepository from '@/dealroadshow/infrastructure/repository/MeetingsRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';

interface IMeetingCounter {
  all: number,
  open: number,
  resolved: number,
  archived: number,
}

const useMeetingsMeta = () => {
  const meetingsRepository = useDIContext().container.get<MeetingsRepository>(MeetingsRepository);
  const [hasMeetings, setHasMeetings] = useState(false);
  const [isMeetingsFetching, setIsMeetingsFetching] = useState(false);
  const [meetingsCounter, setMeetingsCounter] = useState<IMeetingCounter>({
    all: 0,
    archived: 0,
    open: 0,
    resolved: 0,
  });

  const getMeetingsCounter = async (roadshowId: string, searchValue?: string) => {
    setIsMeetingsFetching(true);
    try {
      const response = await meetingsRepository.getQuestionCounter(roadshowId, searchValue);
      setMeetingsCounter(response);
    } catch (errorResponse) {
      AlertManager.error(getErrorMessage(errorResponse));
    } finally {
      setIsMeetingsFetching(false);
    }
  };

  const checkIsRoadshowHasMeetings = async (roadshowId: string): Promise<boolean> => {
    try {
      const isRoadshowHasMeetings = await meetingsRepository.isRoadshowHasMeetings(roadshowId);
      setHasMeetings(isRoadshowHasMeetings);
      return isRoadshowHasMeetings;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      return false;
    }
  };

  const getMeetingsMeta = async (roadshowId: string) => {
    const isRoadshowHasMeetings = await checkIsRoadshowHasMeetings(roadshowId);
    if (isRoadshowHasMeetings) {
      await getMeetingsCounter(roadshowId);
    }
  };

  return {
    meetingsCounter,
    hasMeetings,
    isMeetingsFetching,
    getMeetingsCounter,
    getMeetingsMeta,
  };
};

export const MeetingsMetaContext = createContext<ReturnType<typeof useMeetingsMeta>>(null);

export const useMeetingsMetaContext = () => {
  const context = useContext(MeetingsMetaContext);
  if (!context) {
    throw new Error('useMeetingsMetaContext must be used within a MeetingsMetaContextProvider');
  }
  return context;
};

const MeetingsMetaContextProvider = ({ children }: { children: React.ReactNode }) => (
  <MeetingsMetaContext.Provider value={ useMeetingsMeta() }>{ children }</MeetingsMetaContext.Provider>
);

export default MeetingsMetaContextProvider;
