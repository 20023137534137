import React, { useState, createContext, useContext } from 'react';
import AskAQuestionRepository from '@/dealroadshow/infrastructure/repository/AskAQuestionRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useDIContext } from '@/Framework/DI/DIContext';

interface IQuestionsCounter {
  all: number,
  open: number,
  resolved: number,
  archived: number,
}

const useQuestionsMeta = () => {
  const questionsRepository = useDIContext().container.get<AskAQuestionRepository>(AskAQuestionRepository);
  const [isQuestionsFetching, setIsQuestionsFetching] = useState(false);
  const [hasQuestions, setHasQuestions] = useState(false);
  const [questionsCounter, setQuestionsCounter] = useState<IQuestionsCounter>({
    all: 0,
    open: 0,
    resolved: 0,
    archived: 0,
  });

  const getQuestionsCounter = async (roadshowId: string, isFetching: boolean = true) => {
    if (isFetching) setIsQuestionsFetching(true);
    try {
      const response = await questionsRepository.getQuestionsCounter(roadshowId);
      setQuestionsCounter(response);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      if (isFetching) setIsQuestionsFetching(false);
    }
  };

  const checkIsRoadshowHasQuestions = async (roadshowId: string): Promise<boolean> => {
    try {
      const isRoadshowHasQuestions = await questionsRepository.isRoadshowHasQuestions(roadshowId);
      setHasQuestions(isRoadshowHasQuestions);
      return isRoadshowHasQuestions;
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      return false;
    }
  };

  const getQuestionsMeta = async (roadshowId: string) => {
    const isRoadshowHasQuestions = await checkIsRoadshowHasQuestions(roadshowId);
    if (isRoadshowHasQuestions) {
      getQuestionsCounter(roadshowId);
    }
  };

  return {
    questionsCounter,
    hasQuestions,
    isQuestionsFetching,
    getQuestionsCounter,
    setQuestionsCounter,
    getQuestionsMeta,
  };
};

export const QuestionsMetaContext = createContext<ReturnType<typeof useQuestionsMeta>>(null);

export const useQuestionsMetaContext = () => {
  const context = useContext(QuestionsMetaContext);
  if (!context) {
    throw new Error('useQuestionsMetaContext must be used within a QuestionsMetaContextProvider');
  }
  return context;
};

const QuestionsMetaContextProvider = ({ children }: { children: React.ReactNode }) => (
  <QuestionsMetaContext.Provider value={ useQuestionsMeta() }>{ children }</QuestionsMetaContext.Provider>
);

export default QuestionsMetaContextProvider;
