export const MAP_FILTERS_NAME = {
  // Deal
  dates: 'Dates',
  includeInvestorIds: 'Account & Contact',
  includeContactIds: 'Account & Contact',
  // Cross Deal
  includeTransactionTypeIds: 'Transaction Type',
  includeSubIndustryIds: 'Industry & Subindustry',
  includeAbsSubSectorIds: 'Sector & Subsector',
  includeAccountTypeIds: 'Account Type',
  includeAccountIds: 'Account',
  includeDealIds: 'Deal',
  includeCurrencyIds: 'Currency',
  includeRatingGroupIds: 'Rating',
  includeRatingAgencyIds: 'Rating Agency',
  includeDebtCouponTypeIds: 'Coupon type',
  includeSponsorIds: 'Company / Parent',
  includeUnderwriterIds: 'Underwriter',
  allocationRange: 'Allocation Size',
  spreadSizeRange: 'Pricing Spread',
  tenorWalYearsRange: 'Tenor / WAL',
  yieldSizeRange: 'Yield',
  upsized: 'Upsized Deals Only',

  // Bonds and Bondscreener
  includedCurrencyIds: 'Currency',
  includedRegionIds: 'Region & Country',
  includedStatus: 'Status',
  includedSubsectorIds: 'Sector & Subsector',
  includedIssuerIds: 'Issuer',
  includedDealerIds: 'Dealer',
  currentFaceRange: 'Curr Face',
  currentFaceRangeIds: 'Curr Face',
  walRange: 'WAL',
  includedRatingGroupIds: 'Rating',
  includedStatusIds: 'Status',
  includedRatingAgencyIds: 'Rating Agency',
  includedBenchmarkGroupIds: 'Benchmark',
  spreadRange: 'Spread',
  includedSpeedTypeIds: 'Speed Type',
  speedRange: 'Speed',

  // NRSRO
  includedPseudoSectorIds: 'ABS Sector',
  includedDocumentTypeIds: 'Document Type',
  includedPublisherIds: 'Publisher',

  // Bondscreener
  trancheSizeRange: 'Tranche Size',
  dealSizeRange: 'Deal Size',
  weightedAverageLifeRange: 'WAL',
  pricingSpreadRange: 'SPRD',
  couponRange: 'CPN',
  yieldRange: 'YLD',
  includedCouponTypeIds: 'Coupon Type',
  includedSubindustryIds: 'Industry & Subindustry',
  maturityDateRange: 'MTY',
  tenorRange: 'TNR',
  includedProductIds: 'Credit Product',
  includedRankingIds: 'Ranking',
  includedRegistrationGroupIds: 'Registration Type',
  isUpsized: 'Upsized Deals',
  includedParentIds: 'Parent',

  // Trace
  maturityDate: 'MT',
  price: 'Price',
  coupon: 'Coupon',
  size: 'Size',
  tradeDate: 'Date',
  tradeTime: 'Time',
  includedContraPartyTypes: 'CPT',
  includedRemunerations: 'RMUN Types',
  includedReportingPartyTypes: 'RPT',
  includedReportingPartySides: 'Side',
  includedTradeStatuses: 'Status',
};

export const MAP_FILTERS_PLURAL_NAME = {
  // Deal
  includeInvestorIds: 'Accounts & Contacts',
  includeContactIds: 'Accounts & Contacts',

  // Cross Deal
  includeTransactionTypeIds: 'Transaction Types',
  includeSubIndustryIds: 'Industries & Subindustries',
  includeAbsSubSectorIds: 'Sectors & Subsectors',
  includeAccountTypeIds: 'Account Types',
  includeAccountIds: 'Account',
  includeDealIds: 'Deals',
  includeCurrencyIds: 'Currencies',
  includeRatingGroupIds: 'Ratings',
  includeRatingAgencyIds: 'Rating Agencies',
  includeDebtCouponTypeIds: 'Coupon types',
  includeSponsorIds: 'Company / Parent',
  includeUnderwriterIds: 'Underwriter',
  allocationRange: 'Allocation Size',
  spreadSizeRange: 'Pricing Spread',
  tenorWalYearsRange: 'Tenor / WAL',
  yieldSizeRange: 'Yield',

  // Bonds and Bondscreener
  includedCurrencyIds: 'Currencies',
  includedRegionIds: 'Regions & Countries',
  includedStatus: 'Status',
  includedSubsectorIds: 'Sectors & Subsectors',
  includedIssuerIds: 'Issuers',
  includedDealerIds: 'Dealers',
  currentFaceRange: 'Curr Face',
  currentFaceRangeIds: 'Curr Face',
  walRange: 'WAL',
  includedRatingGroupIds: 'Ratings',
  includedStatusIds: 'Statuses',
  includedRatingAgencyIds: 'Rating Agencies',
  includedBenchmarkGroupIds: 'Benchmarks',
  spreadRange: 'Spread',
  includedSpeedTypeIds: 'Speed Types',
  speedRange: 'Speed',

  // NRSRO
  includedPseudoSectorIds: 'ABS Sectors',
  includedDocumentTypeIds: 'Document Types',
  includedPublisherIds: 'Publishers',

  // Bondscreener
  trancheSizeRange: 'Size',
  weightedAverageLifeRange: 'WAL',
  pricingSpreadRange: 'SPRD',
  couponRange: 'CPN',
  yieldRange: 'YLD',
  includedCouponTypeIds: 'Int. Rates',
  includedSubindustryIds: 'Industries & Subindustries',
  maturityDateRange: 'MTY',
  includedProductIds: 'Credit Products',
  tenorRange: 'TNR',
  includedRankingIds: 'Rankings',
  includedRegistrationGroupIds: 'Registration Types',
  isUpsized: 'Upsized Deals',
  includedParentIds: 'Parent',

  // Trace
  maturityDate: 'MT',
  price: 'Price',
  size: 'Size',
  tradeDate: 'Date',
  tradeTime: 'Time',
  includedContraPartyTypes: 'CPTs',
  includedRemunerations: 'RMUN Types',
  includedReportingPartyTypes: 'RPTs',
  includedReportingPartySides: 'Side',
  includedTradeStatuses: 'Status',
};

export const MAP_STATUS_LABEL = {
  all: 'All',
  new: 'New (24 hrs)',
  live: 'Live Only',
};
