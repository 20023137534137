import React, { createContext, useContext, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import {
  AUTO_SEND_ACCESS_REQUEST,
  AUTO_SEND_FOLLOW,
  getSetsTitle,
} from '@/dataroom/application/accessRequests/constants';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMessage, getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import DataroomRepository from '@/dataroom/infrastructure/repository/DataroomRepository';
import RestrictedRepository from '@/dataroom/infrastructure/repository/RestrictedRepository';
import FollowRepository from '@/dataroom/infrastructure/repository/FollowRepository';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { SessionService } from '@/users/application/Session/SessionService';
import UserPermission from '@/users/domain/UserPermission';
import config from '@/dataroom/application/config/config';

const useAccessRequest = () => {
  const { container } = useDIContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isAccessRequestModalVisible, setIsAccessRequestModalVisible] = useState<boolean>(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(false);
  const [dataroom, setDataroom] = useState<{
    id: number,
    name: string,
  }>(null);
  const [autoSendAction, setAutoSendAction] = useState<string>(null);

  const { tenant } = useDataroomTenantContext();

  const requestAccess = async (
    dataroom: {
      id: number,
      name: string,
    },
    withSessionCheck: boolean = false,
  ): Promise<void> => {
    const sessionService = container.get<SessionService>(SessionService);

    setDataroom(dataroom);
    setAutoSendAction(AUTO_SEND_ACCESS_REQUEST);

    const accessPermissions = withSessionCheck ? UserPermission.managerAccess : UserPermission.publicAccess;

    if (await sessionService.satisfiesAccessPermissions(accessPermissions)) {
      if (withSessionCheck) {
        window.location.href = dataroomUrl(tenant).getAccessRequestUrl(dataroom.id, dataroom.name);
        return;
      }
      setIsAccessRequestModalVisible(true);
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const sendAccessRequest = async (formData: {
    role: string,
    message: string,
    salesRepresentative?: string,
  }): Promise<void> => {
    setIsFetching(true);

    try {
      const { role, message, salesRepresentative } = formData;
      const salesRepresentativeValue = salesRepresentative ? { salesRepresentative } : {};

      const payload = { dataroomId: dataroom.id, role, message, ...salesRepresentativeValue };
      const restrictedRepository = container.get<RestrictedRepository>(RestrictedRepository);

      await restrictedRepository.requestAccess(payload);

      setIsAccessRequestModalVisible(false);

      AlertManager.success(getMessage(messageCodes.DATAROOM_ACCESS_REQUEST_SUCCESS, {
        dataroomName: dataroom.name,
        tenantName: DataroomTenantConfig.fromCode(tenant).options.tenantLabel,
      }));
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsAccessRequestModalVisible(false);
      setIsFetching(false);
    }
  };

  const sendFollowRequest = async (
    dataroom: {
      id: number,
      name: string,
    },
    withSessionCheck = false,
  ): Promise<void> => {
    const sessionService = container.get<SessionService>(SessionService);

    const tenantTabName = config.tenant.tenantInvestorSet.code === tenant
      ? 'Set'
      : DataroomTenantConfig.fromCode(tenant).name;

    setDataroom(dataroom);
    setAutoSendAction(AUTO_SEND_FOLLOW);

    const accessPermissions = withSessionCheck ? UserPermission.managerAccess : UserPermission.publicAccess;

    if (await sessionService.satisfiesAccessPermissions(accessPermissions)) {
      setIsFetching(true);
      try {
        const followRepository = container.get<FollowRepository>(FollowRepository);
        await followRepository.follow({ dataroomId: dataroom.id });
        AlertManager.success(getMessage(messageCodes.DATAROOM_FOLLOW_SUCCESS, {
          dataroomName: dataroom.name,
          tabName: `My ${ tenantTabName }s`,
          tenantName: DataroomTenantConfig.fromCode(tenant).name,
        }));
      } catch (error) {
        AlertManager.error(getErrorMessage(error));
      } finally {
        setIsFetching(false);
      }
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const sendSilentFollowRequest = (dataroomId: number): Promise<void> => {
    setIsFetching(true);

    try {
      const followRepository = container.get<FollowRepository>(FollowRepository);
      return followRepository.follow({ dataroomId });
    } finally {
      setIsFetching(false);
    }
  };

  const sendUnfollowRequest = async (dataroom: {
    id: number,
    name: string,
    displayName: string,
  }): Promise<void> => {
    setIsFetching(true);

    try {
      const followRepository = container.get<FollowRepository>(FollowRepository);
      const { success } = await followRepository.unfollow({ dataroomId: dataroom.id });

      if (success) {
        AlertManager.success(getMessage(messageCodes.DATAROOM_SUCCESSFULLY_UNFOLLOWED, {
          dataroomName: dataroom.displayName,
          label: DataroomTenantConfig.fromCode(tenant).options.tenantLabel,
          title: getSetsTitle(tenant),
        }));
      } else {
        AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
      }
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const canFollow = (dataroomId: number): Promise<boolean> => {
    const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
    return dataroomRepository.canFollow({ dataroomId });
  };

  const canRequestAccess = async (dataroomId: number): Promise<boolean> => {
    try {
      const payload = { dataroomId };

      const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
      const { allowance } = await dataroomRepository.canRequestAccess(payload);

      if (allowance === 'accessDenied') {
        AlertManager.error(getMessage(messageCodes.DATAROOM_ACCESS_DENIED));
        return false;
      }

      return allowance === 'requestAllowed';
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      return false;
    }
  };

  return {
    isFetching,
    isAccessRequestModalVisible,
    setIsAccessRequestModalVisible,
    isLoginModalVisible,
    setIsLoginModalVisible,
    dataroom,
    setDataroom,
    autoSendAction,
    requestAccess,
    sendAccessRequest,
    sendFollowRequest,
    sendSilentFollowRequest,
    sendUnfollowRequest,
    canFollow,
    canRequestAccess,
  };
};

type AccessRequestContextType = ReturnType<typeof useAccessRequest>;

export const AccessRequestContext = createContext<AccessRequestContextType>(null);

export const useAccessRequestContext = () => {
  const context = useContext(AccessRequestContext);
  if (!context) {
    throw new Error('useAccessRequestContext must be used within a AccessRequestContextProvider');
  }
  return context;
};

interface IProps {
  children: React.ReactNode,
}

const AccessRequestContextProvider = ({ children }: IProps) => (
  <AccessRequestContext.Provider value={ useAccessRequest() }>
    { children }
  </AccessRequestContext.Provider>
);

export default AccessRequestContextProvider;
