import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useDataroomTwoFactorAuthenticationContext } from './DataroomTwoFactorAuthenticationContext';
import TwoFactorAuthenticationModal from '@/users/ui/components/TwoFactorAuthentication/TwoFactorAuthenticationModal';

const DataroomTwoFactorAuthenticationModal = () => {
  const { currentUser } = useSessionContext();

  const {
    isModalOpen,
    entityName,
    onSuccessCallback,
    resetModalData,
    onCloseCallback,
  } = useDataroomTwoFactorAuthenticationContext();

  if (isEmpty(currentUser)) {
    return null;
  }

  const onClose = () => {
    onCloseCallback();
    resetModalData();
  };

  const successCallback = () => {
    onSuccessCallback();
    resetModalData();
  };

  const description = <>Access to <b>{ entityName }</b> requires two-factor authentication.</>;

  return (
    <TwoFactorAuthenticationModal
      isVisible={ isModalOpen }
      onReset={ resetModalData }
      onClose={ onClose }
      description={ description }
      successCallback={ successCallback }
      addMobileTwoFactorEnabled
    />
  );
};

export default DataroomTwoFactorAuthenticationModal;
