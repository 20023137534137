import { useEffect, useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';
import { ICollectionItem } from '@/allocate/domain/vo/Allocations/Upload/DealManagers';
import { steps, getUploadUrl, changeEditingStep } from '@/allocate/application/Allocations/Upload/config';

const useDealManagers = () => {
  const { push, query: { from } } = useRouter();
  const { container } = useDIContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);

  const {
    tenant,
    isEditing,
    dealAllocationId,
    linkedRoadshowId,
  } = useUploadContext();

  const [managersList, setManagersList] = useState<ICollectionItem[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Get deal managers list
   */
  const getManagers = async (): Promise<void> => {
    try {
      const { collection } = await allocationsUploadRepository.getManagersList({ dealAllocationId });
      setManagersList(collection);
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  /**
   * Save deal managers list
   */
  const saveManagers = async (dealManagers: ICollectionItem[]): Promise<void> => {
    setIsSubmitting(true);
    try {
      if (!linkedRoadshowId) {
        const filteredDealManagers = dealManagers.filter((manager) => !!manager.corporateEmail);
        await allocationsUploadRepository.saveManagers({
          dealManagers: filteredDealManagers,
          dealAllocationId,
        });
      }

      if (isEditing) {
        changeEditingStep(steps.enhancedTagging, dealAllocationId, from?.toString());
      } else {
        await push(getUploadUrl(tenant, steps.enhancedTagging));
      }
    } catch (error) {
      AlertManager.error(getErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (dealAllocationId) {
      getManagers();
    }
  }, [dealAllocationId]);

  return {
    isFetching,
    managersList,
    saveManagers,
    isSubmitting,
  };
};

export default useDealManagers;
